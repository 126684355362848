<template>
  <div class="slider">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      fade
    >
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302879"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302879" /></a>
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302865"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302865" /></a>
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302834"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302834" /></a>
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302837"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302837" /></a>
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302846"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302846" /></a>
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302853"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302853" /></a>
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302858"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302858" /></a>
      <a target="_blank" href="https://raddo-ethnodoc.com/raddo/document/302872"><b-carousel-slide img-src="https://raddo-ethnodoc.com/archive/302872" /></a>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "slider",
  title: "Slider",
};
</script>
